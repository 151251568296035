.team-configure-wrapper {
    display: flex;
    flex-direction: column;
    height: calc(100% - 13.5rem);

    .tab-wrapper.teamConfig-tab-wrapper{
        height: 100%;
        .tab-content{
            height: calc(100% - 3rem);
        }
    }


    .save-button {
        border-right: 1px solid;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .publish-button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 1px solid;
    }

    .button-with-tooltip{
        border-radius: 0.375rem;
        background-color: #000080;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .button-with-tooltip.disabled{
        opacity: 0.65;
    }

    .go-back-wrapper{
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }
}